export const getItem = (key: string) => {
    try {
        const data = localStorage.getItem(key)
        return data && data != 'null' ? JSON.parse(data) : null
    } catch (e: any) {
        console.error(e)
        return false
    }
}
export const setItem = (key: string, value: any) => {
    try {
        localStorage.setItem(key, JSON.stringify(value))
        return true
    } catch (e: any) {
        console.error(e)
        return false
    }
}
export const removeItem = (key: string) => {
    try {
        localStorage.removeItem(key)
        return true
    } catch (e: any) {
        console.error(e)
        return false
    }
}
export const getKeyItem = (target: string, key: string) => {
    try {
        const raw = localStorage.getItem(target)
        const data = raw && raw != 'null' ? JSON.parse(raw) : null
        if(!data) return null
        return data[key]
    } catch (e: any) {
        console.error(e)
        return false
    }
}
export const updateKeyItem = (target: string, key: string, value: any) => {
    try {
        const raw = localStorage.getItem(target)
        const data = raw && raw != 'null' ? JSON.parse(raw) : {}
        data[key] = value
        localStorage.setItem(target, JSON.stringify(data))
        return true
    } catch (e: any) {
        console.error(e)
        return false
    }
}
export const updateItems = (target: string, value: any) => {
    try {
        const raw = localStorage.getItem(target)
        const data = raw && raw != 'null' ? JSON.parse(raw) : {}
        for (const key in value) {
            data[key] = value[key] ? value[key] : data[key]
        }
        localStorage.setItem(target, JSON.stringify(data))
        return true
    } catch (e: any) {
        console.error(e)
        return false
    }
}
