import axios from 'axios'
import * as storage from './storage'
const isLocal = location.protocol === 'http:'
const isDev = location.hostname === 'mr9vuzmzfjzcjtomqmgl.vercel.app'
//const isLocal = true
export const host = isLocal ? `http://localhost:8020/api` : isDev ? `https://umm44ktpwwxjopfvz283.8i9.me/api` : `https://api.vets-index.com/api`
type Role = 'student' | 'admin' | 'hospital'
export const get = async (endpoint: string, skipLogin?: boolean, adminPrivilege?: boolean) => {
    try {
        if (!skipLogin) await refresh()
        const token = await storage.getKeyItem('credential', 'accessToken')
        const role = location.pathname.match(/^\/([a-zA-Z0-9-_]+)\/.+/)
        const storagedRole = await storage.getKeyItem('credential', 'role')
        const prefix = role ? role[1] : 'my'
        const hereRole = prefix === 'my' ? 'student' : prefix
        if (!skipLogin) {
            if (!adminPrivilege || (adminPrivilege && storagedRole !== 'admin')) {
                if (!token || storagedRole !== hereRole) return location.href = `/${prefix}/login?go=${encodeURIComponent(location.href)}`
            }
        }
        const data = await axios.get(`${host}${endpoint}`, {
            headers: {
                Role: storagedRole,
                Authorization: `Bearer ${token}`
            }
        })
        if (data.data.error) return data.data
        return data.data
    } catch (e: any) {
        throw {
            error: true,
            success: false,
            message: errorMessage('GET', maskEndpoint(endpoint), e)
        }
    }
}
export const post = async (endpoint: string, postData: any, adminPrivilege?: boolean) => {
    try {
        await refresh()
        const token = await storage.getKeyItem('credential', 'accessToken')
        const role = location.pathname.match(/^\/([a-zA-Z0-9-_]+)\/.+/)
        const storagedRole = await storage.getKeyItem('credential', 'role')
        if (!role || !role[1]) return location.href = `https://vets-index.com`
        const hereRole = role[1] === 'my' || role[1] === 'apply' ? 'student' : role[1]
        if ((!adminPrivilege || (adminPrivilege && storagedRole !== 'admin')) && hereRole !== 'student') {
            if ((!token || storagedRole !== hereRole) && role[1] !== 'search') return location.href = `/${role[1]}/login?go=${encodeURIComponent(location.href)}`
        }
        const data = await axios.post(`${host}${endpoint}`, postData, {
            headers: {
                Role: storagedRole,
                Authorization: `Bearer ${token}`
            }
        })
        if (data.data.error) return data.data
        return data.data
    } catch (e: any) {
        throw {
            error: true,
            success: false,
            message: errorMessage('POST', maskEndpoint(endpoint), e)
        }
    }
}
export const postWithoutToken = async (endpoint: string, postData: any) => {
    try {
        const data = await axios.post(`${host}${endpoint}`, postData)
        if (data.data.error) return data.data
        return data.data
    } catch (e: any) {
        throw {
            error: true,
            success: false,
            message: errorMessage('POST WT', maskEndpoint(endpoint), e)
        }
    }
}
export const postAsLogined = async (endpoint: string, postData: any, adminPrivilege?: boolean) => {
    try {
        const token = await storage.getKeyItem('credential', 'accessToken')
        const storagedRole = await storage.getKeyItem('credential', 'role')
        if (token && storagedRole === 'student') await refresh('student')
        const headersIfLogined = {
            Role: storagedRole,
            Authorization: `Bearer ${token}`
        }
        const headers = token && storagedRole ? headersIfLogined : {}
        const data = await axios.post(`${host}${endpoint}`, postData, { headers })
        if (data.data.error) return data.data
        return data.data
    } catch (e: any) {
        throw {
            error: true,
            success: false,
            message: errorMessage('POST', maskEndpoint(endpoint), e)
        }
    }
}
export const refresh = async (roleRaw?: string) => {
    console.log('refresh')
    const lastTimeUpd = await storage.getKeyItem('credential', 'lastTimeUpd')
    let roles = roleRaw ? [roleRaw, roleRaw] : null
    if (!roles) roles = location.pathname.match(/^\/([a-zA-Z0-9-_]+)\/.+/)
    if (!roles || !roles[1]) return location.href = `https://vets-index.com`
    const role = roles[1]
    const prefix = role === 'student' ? 'my' : role
    if (!lastTimeUpd) {
        storage.updateKeyItem('credential', 'lastTimeUpd', new Date().getTime().toString())
        return true
    }
    if (parseInt(lastTimeUpd, 10) + 86400000 < new Date().getTime()) {
        const refresh = await storage.getKeyItem('credential', 'refreshToken')
        const raw = await storage.getKeyItem('credential', 'userData')
        if (!raw || !refresh) return location.href = `/${prefix}/login?go=${encodeURIComponent(location.href)}`
        const { username } = raw
        try {
            const data = await postWithoutToken(`/v1/refresh`, { refreshToken: refresh, username, role })
            console.log('refresh', data)
            if (data.accessToken) {
                const update = {
                    accessToken: data.accessToken,
                    refreshToken: data.refreshToken,
                    lastTimeUpd: new Date().getTime().toString()
                }
                storage.updateItems('credential', update)
            } else {
                location.href = `/${prefix}/login?go=${encodeURIComponent(location.href)}`
            }
        } catch {
            location.href = `/${prefix}/login?go=${encodeURIComponent(location.href)}`
        }

    }
}
export const isLoginned = (role?: string) => role ? storage.getKeyItem('credential', 'role') === role : !!storage.getKeyItem('credential', 'role')
export const isAdmin = () => storage.getKeyItem('credential', 'role') === 'admin'
const maskEndpoint = (endpoint: string) => {
    if (isDev || isLocal) return endpoint
    const dirs = endpoint.split('/')
    const masked = []
    for (let i = 0; i < dirs.length; i++) {
        const dir = dirs[i]
        let letters = ''
        for (let i = 1; i < dir.length; i++) {
            letters = letters + '*'
        }
        const maskedDir = dir.replace(/(.{1}).+$/, '$1') + letters
        masked.push(maskedDir)
    }
    return masked.join('/')
}
function errorMessage(method: string, endpoint: string, data: any) {
    console.error(data)
    if (typeof data === 'string') {
        return `${method}(${endpoint}) ${data}`
    } else {
        if (!data.response) {
            if (data.message) return data.message
            return data
        }
        if (!data.response.data) return data
        const r = data.response.data
        return `${r.message}(${r.note})`
    }
}